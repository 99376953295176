
export function headerBtn() {
	const btn = document.querySelector('.gnav__btn');
	var menu_target = document.querySelector('.gnav');
	const sbtn = document.querySelector('#service-menu__btn');
	var smenu_target = document.querySelector('#service-menu');


	const cbtn = document.querySelector('.service-menu__close');


	let state = false;
	let sstate = false;

	btn.addEventListener("click", function () {
		let scrollbarWidth = window.innerWidth - document.body.clientWidth;
		const vh = window.innerHeight;
		const windowHeight = document.documentElement.clientHeight;
		const header = document.querySelector('.header');
		const headerHeight = header.offsetHeight;
		if (state == false) {
			this.classList.add('active');
			//html.classList.add('fixed');
			menu_target.classList.add('active');
			document.querySelector('body').classList.add('fixed');
			btn.style.marginRight = scrollbarWidth + 'px';
			document.querySelector('body').style.marginRight = scrollbarWidth + 'px';
			state = true;
			sbtn.classList.add('active');
		} else {
			this.classList.remove('active');
			//html.classList.remove('fixed');
			menu_target.classList.remove('active');
			document.querySelector('body').classList.remove('fixed');
			btn.style.marginRight = '0px';
			document.querySelector('body').style.marginRight = '0px';
			state = false;
			sbtn.classList.remove('active');
		}
	});


	if (sbtn) {
		sbtn.addEventListener("click", function () {
			let scrollbarWidth = window.innerWidth - document.body.clientWidth;
			const vh = window.innerHeight;
			const windowHeight = document.documentElement.clientHeight;
			const header = document.querySelector('.header');
			const headerHeight = header.offsetHeight;
			if (sstate == false) {
				this.classList.add('active');
				//html.classList.add('fixed');
				smenu_target.classList.add('active');
				document.querySelector('body').classList.add('fixed');
				sbtn.style.marginRight = scrollbarWidth + 'px';
				document.querySelector('body').style.marginRight = scrollbarWidth + 'px';
				sstate = true;
			} else {
				this.classList.remove('active');
				//html.classList.remove('fixed');
				smenu_target.classList.remove('active');
				document.querySelector('body').classList.remove('fixed');
				sbtn.style.marginRight = '0px';
				document.querySelector('body').style.marginRight = '0px';
				sstate = false;
			}
		});
	}

	if (cbtn) {
		cbtn.addEventListener("click", function () {
			sbtn.classList.remove('active');
			smenu_target.classList.remove('active');
			document.querySelector('body').classList.remove('fixed');
			sbtn.style.marginRight = '0px';
			document.querySelector('body').style.marginRight = '0px';
			sstate = false;
		});
	}


}
