
export function anchorLink() {
	//アンカーリンク
	const anchorLinks = document.querySelectorAll("a[href*='#']");
	const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
	const header = document.querySelector('.header');
	const anchorHash = location.hash;

	function getDir(place, n) {
		return place.pathname.replace(new RegExp("(?:\\\/+[^\\\/]*){0," + ((n || 0) + 1) + "}$"), "/");
	}

	//ハッシュ値でのアンカーリンク
	anchorLinksArr.forEach(link => {
		console.log(link);

		var local = window.location;
		var url = local.origin;
		url = getDir(local);


		link.addEventListener('click', e => {
			if (url == link.getAttribute('href').replace(/#.*$/, "")) {
				e.preventDefault();
				const targetId = link.hash;
				const targetElement = document.querySelector(targetId);
				const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
				const headerHeight = header.clientHeight * 1.5;
				//const totalScrollAmount = targetOffsetTop - headerHeight;
				const totalScrollAmount = targetOffsetTop - headerHeight;

				window.scrollTo({
					top: totalScrollAmount,
					behavior: "smooth"
				});
			}
		});

	});

	if (anchorHash) {
		window.scrollTo({
			top: 0,
			behavior: "instant"
		});
		const targetElement = document.querySelector(anchorHash);
		const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
		const headerHeight = header.clientHeight * 1.5;
		const totalScrollAmount = targetOffsetTop - headerHeight;
		//const totalScrollAmount = targetOffsetTop - 40;

		var fn = function () {
			window.scrollTo({
				top: totalScrollAmount,
				behavior: "smooth"
			});
		};
		var tm = 500;
		setTimeout(fn, tm);
	}

}
