import gsap from "gsap";
export function headerScrollTransition() {
	let set_position = 0;
	window.addEventListener('scroll', function () {
		const siteHeader = document.querySelector(".header");
		if (document.documentElement.scrollTop > 100) {
			siteHeader.classList.add(('js-scroll-down'));
		}else{
			siteHeader.classList.remove(('js-scroll-down'));
		}
		set_position = document.documentElement.scrollTop;

	});

}
