

//ヘッダーボタン
import { headerBtn } from './module/common/headerBtn';

//次のブロックに差し掛かると前のブロックを暗く
import { beforeSectionTransistion } from './module/common/beforeSectionTrantsition';

//次のブロックに差し掛かると前のブロックを暗く
import { anchorLink } from './module/common/anchorLink';

//ヘッダースクロール
import { headerScrollTransition } from './module/common/headerScrollTrantition';

//ブロックアニメーション
import { animBlock } from './module/common/animBlock';

//履歴のスクロール位置無効化
if (history.scrollRestoration) {
	history.scrollRestoration = "auto";
}

//
window.addEventListener("load", function () {

	//
	headerBtn();

	//
	beforeSectionTransistion();

	//
	anchorLink();

	//
	headerScrollTransition();

	if( !document.querySelector("body").classList.contains('top') ){
		animBlock();
	}

});
