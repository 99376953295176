import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function beforeSectionTransistion() {
	gsap.utils.toArray('.js-transition-section').forEach((el) => {
		gsap.fromTo(el, {
			ease:"none",
			//filter: 'brightness(1)',
			//y: 0,
		}, {
			ease:"none",
			//y: -40,
			//filter: 'brightness(0)',
			//duration: 1
			scrollTrigger: {
				trigger: el,
				start: "bottom-=" + window.innerHeight * .5 + "px top",
				end: "bottom top",
				/*start:"top top",
				end:"bottom top",*/
				scrub: 0,
				anticipatePin: 1,
				onUpdate:function(t){
					var e = el;
					var n=.7*t.progress,o=200*n*n+"px";
					gsap.to(e,{duration:.01}),e.style.filter="brightness("+(1-n)+")"
				}
			}
		});
	});

}

/*
var e=document.querySelector(".fv .parallax_section");
r.p8.to(e,{ease:"none",scrollTrigger:{trigger:e,start:"top top",end:"bottom top",scrub:0,onUpdate:function(t){var n=.7*t.progress,o=200*n*n+"px";
r.p8.to(e,{y:o,duration:.01}),e.style.filter="brightness("+(1-n)+")"}}});
*/
